@import 'portal-constants';
@import 'shared-styles';

button.por-btn {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &:disabled {
    cursor: default;
  }

  &:not([disabled]) {
    &:not(.por-btn--light-actions) {
      @include applyHover($color-gray-9, 0.08);

      &:active {
        @include addLayer($color-gray-9, 0.12);
      }
    }

    &.por-btn--light-actions {
      @include applyHover($color-white, 0.08);

      &:active {
        @include addLayer($color-white, 0.12);
      }
    }
  }
}
