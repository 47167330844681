@import 'portal-constants';

mat-checkbox.no-space-checkbox .mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: #{$color-gray-7};
  --mdc-checkbox-disabled-unselected-icon-color: #{$color-gray-3};

  padding: 0;
  width: 1.4rem;
  height: 1.4rem;

  .mdc-checkbox__background {
    width: 1.4rem;
    height: 1.4rem;
    top: -0.3rem;
    left: 0;
  }

  input[type='checkbox'] {
    width: 1.4rem;
    height: 1.4rem;
  }
}
