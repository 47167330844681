// KEEP IN SYNC WITH shared-styles.less!!

// ellipse a text after the given line count - works for any line count, see https://css-tricks.com/almanac/properties/l/line-clamp/
@mixin ellipse($lineCount: 1) {
  @if ($lineCount == 1) {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @if ($lineCount > 1) {
    display: -webkit-box;
    -webkit-line-clamp: $lineCount;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: anywhere;
  }
}

@mixin applyLayerStyles($color, $opacity) {
  content: '';
  position: absolute;
  z-index: 10000; // we want to be above every other layer here
  inset: 0;
  background-color: $color;
  opacity: $opacity;
  border-radius: inherit;
  pointer-events: none; // do not mess with pointer events of the actual element
}

@mixin applyHover($color, $opacity) {
  position: relative;

  &:hover:before {
    @include applyLayerStyles($color, $opacity);
  }
}

@mixin addLayer($color, $opacity) {
  position: relative;

  &:before {
    @include applyLayerStyles($color, $opacity);
  }
}

@mixin spinner($color, $width: 0.4rem) {
  display: block;
  border-radius: 50%;
  border: $width solid $color;
  border-right-color: transparent;
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

@mixin skeletonLoader($opacity: 0.5, $duration: 0.8s) {
  background: linear-gradient(30deg, fade-out($color-blue-gray-050, 0.2), fade-out($color-blue-gray-050, 0.4) 100%);

  @include pulseAnimation();
}

@mixin pulseAnimation($opacity: 0.7, $duration: 1.5s) {
  animation: pulse $duration ease-in-out infinite;

  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: $opacity;
    }
  }
}
