@use 'sass:list';

// prettier-ignore
$colorsHsl: [
  [ 'white', 0, 100, 100],
  [ 'black', 0, 0, 0],
  [ 'error', 0, 100, 62],
  [ 'primary', 125, 50, 50], // we dont have a primary yet, so this is just random atm
  [ 'gray-1', 210, 36.4, 95.7],
  [ 'gray-2', 212, 33.3, 88.8],
  [ 'gray-3', 210, 31.4, 80],
  [ 'gray-4', 211, 27.2, 70.4],
  [ 'gray-5', 209, 23.2, 60.2],
  [ 'gray-6', 210, 21.6, 49],
  [ 'gray-7', 209, 28.4, 39.4],
  [ 'gray-8', 209, 34.2, 30.4],
  [ 'gray-9', 211, 39.5, 23.3],
  [ 'gray-10', 209, 61.4, 16.3]
];

:host,
.cdk-overlay-container.portal-overlay {
  --font-family: 'Open Sans', sans-serif;
  --font-display: normal 700 2.8rem/3.6rem var(--font-family);
  --font-headline: normal 700 2.4rem/3.2rem var(--font-family);
  --font-title: normal 600 2rem/2.8rem var(--font-family);
  --font-subheader: normal 600 1.6rem/2.4rem var(--font-family);
  --font-body: normal 400 1.3rem/2rem var(--font-family);
  --font-caption: normal 400 1.1rem/1.6rem var(--font-family);
  --font-button: normal 400 1.2rem/2.2rem var(--font-family);
  --clm-virtual-list-item-padding-right: 1.2rem;

  // loop over all colors (variants) and create css variables for each hsl property and the combined hsl value
  // we need the individual properties to do modifications on hue, saturation, lightness and opacity
  // if we introduce advanced theming, this will probably be done via code through a theming service
  @each $color-parts in $colorsHsl {
    $color-name: color-#{list.nth($color-parts, 1)};
    --#{$color-name}-h: #{list.nth($color-parts, 2)};
    --#{$color-name}-s: #{list.nth($color-parts, 3)}%;
    --#{$color-name}-l: #{list.nth($color-parts, 4)}%;
    --#{$color-name}: hsl(var(--#{$color-name}-h), var(--#{$color-name}-s), var(--#{$color-name}-l));
  }
}
