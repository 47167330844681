@import 'portal-constants';

mat-slide-toggle {
  --mdc-switch-track-width: 2.8rem;
  --mdc-switch-handle-width: 1.8rem;
  --mdc-switch-handle-height: 1.8rem;
  --mdc-switch-disabled-selected-icon-opacity: 0;
  --mdc-switch-disabled-unselected-icon-opacity: 0;
  --mdc-switch-handle-surface-color: #{$color-gray-6};
  --mdc-switch-disabled-handle-opacity: 0;
  --mdc-switch-disabled-unselected-track-color: #{$color-gray-2};
  --mdc-switch-disabled-selected-track-color: #{$color-gray-2};
  --mdc-switch-disabled-track-opacity: 1;
}
