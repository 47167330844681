/* You can add global styles to this file, and also import other style files */
@import 'portal-components';
@import 'portal-theme';
@import 'portal-material-theme';
@import 'dialog-styles';

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html {
  // define how much 1rem should be
  font-size: 10px;
}

body {
  margin: 0; // remove default body styles
}

.containment-context {
  container-type: inline-size;
}

// added this because the injection token (setting the disableTooltipInteractivity to true) is not working if the tooltip trigger is inside an overlay
.mat-tooltip-panel {
  pointer-events: none;
}

#sto-portal:not(.macos) {
  // for safari its not applied but it is for chrome on mac which has a bug that "both-edges" is reserving too much space (cuts off elements)
  scrollbar-gutter: stable both-edges;
}
