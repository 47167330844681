@use '@angular/material' as mat;
@import 'base-theme';
@import 'portal-constants';

@include mat.tooltip-theme($por-theme);

.mdc-tooltip__surface,
.mat-tooltip {
  background-color: $color-gray-8 !important;
  border-radius: 0.4rem !important;
  padding: 0.5rem 0.6rem 0.5rem 0.7rem !important;
}
