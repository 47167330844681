@import (reference, optional) "constants";

// Firefox is not supporting webkit-scrollbar styles, so we have to fallback to a firefox-specific subset of styling options
// It's not 100% the same as on chrome but still very similar and more than good enough for us
@-moz-document url-prefix() {
  .celum-scrollbar, .celum-scrollbar--dark {
    overflow-y: auto;
    scrollbar-width: thin;
  }

  .celum-scrollbar {
    scrollbar-color: var(--clm-native-scrollbar-thumb-color, fade(@color-blue-gray-400, 50)) var(--clm-native-scrollbar-track-color, @color-blue-gray-050);
  }

  .celum-scrollbar--dark {
    scrollbar-color: fade(white, 50) fade(black, 30);
  }

  .celum-scrollbar-child {
    margin-right: unset;
  }
}

// scrollbar-width is only supported by firefox, so we can put styles in here in case any other major browser is used (they support webkit-scrollbar)
@supports not (scrollbar-width: thin) {
  .celum-scrollbar:not(perfect-scrollbar), .celum-scrollbar--dark:not(perfect-scrollbar) {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 1rem;
      height: 1rem;
      background-color: transparent;
      transition: background-color 0.2s ease;
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-corner {
      background: transparent; // if both horizontal and vertical scrollbars are active in dark mode, corner would be white
    }
  }

  .celum-scrollbar {
    &::-webkit-scrollbar:hover {
      background-color: var(--clm-native-scrollbar-track-color, @color-blue-gray-050);
    }

    .thumb-gradient(var(--clm-native-scrollbar-thumb-color, fade(@color-blue-gray-400, 50)));
  }

  .celum-scrollbar--dark {
    &::-webkit-scrollbar:hover {
      background-color: fade(black, 30);
    }

    .thumb-gradient(fade(white, 50));
  }
}

// workaround non-existing padding support on scrollbars, as padding is not supported on scrollbars
.thumb-gradient(@color) {
  &::-webkit-scrollbar-thumb {
    .base-thumb-gradient(~"transparent 30%, @{color} 31%, @{color} 69%, transparent 70%, transparent 100%");
  }

  &::-webkit-scrollbar-thumb:hover {
    .base-thumb-gradient(~"transparent 20%, @{color} 21%, @{color} 79%, transparent 80%, transparent 100%");
  }
}

.base-thumb-gradient(@gradient) {
  background: ~"linear-gradient(90deg,  @{gradient})";

  &:horizontal {
    background: ~"linear-gradient(180deg, @{gradient})";
  }
}
